<template>
    <div id="filter-panel">
        <div
            class="filter-section"
            :style="hideFilterPanel ? 'display: block' : 'display: none'"
        >
            <div class="filter-header-section">
                <div class="filter-header">
                    <button-component
                        style="
                            height: fit-content;
                            margin: 12px 0;
                            margin-right: 10px;
                        "
                        :onClick="openFilterPanel"
                        iconName="funnel"
                        buttonName="Clear"
                        buttonVariation="icon-only"
                    ></button-component>
                </div>
            </div>
            <div
                class="filter-header"
                style="justify-content: center; padding: 0; margin: 16px 0"
            >
                <button-component
                    style="height: fit-content; margin: 12px 0px"
                    :onClick="openFilterPanel"
                    iconName="angle-double-left"
                    buttonName="Hide"
                    buttonVariation="icon-only"
                ></button-component>
            </div>
        </div>
        <div
            class="filter-section"
            :style="
                !hideFilterPanel ? 'visibility: visible' : 'visibility: hidden'
            "
        >
            <div class="filter-header-section">
                <div class="filter-header">
                    <button-component
                        style="
                            height: fit-content;
                            margin: 12px 0;
                            margin-right: 10px;
                        "
                        :onClick="openFilterPanel"
                        iconName="funnel"
                        buttonName="Clear"
                        buttonVariation="icon-only"
                    ></button-component>
                    <h4 style="margin: 12px 0">Filter</h4>
                </div>
                <div class="reset-button">
                    <button-component
                        :onClick="clearAll"
                        buttonName="Clear All"
                        buttonVariation="flat"
                        style="margin: 12px 0"
                        :isDisabled="isClearAllDisabled"
                    ></button-component>
                </div>
            </div>
            <div style="padding: 0 16px">
                <div class="filter-header-hide-filterPanel">
                    <button-component
                        style="
                            height: fit-content;
                            margin: 0;
                            margin-right: 10px;
                        "
                        :onClick="onClickHideFilterPannel"
                        iconName="angle-double-right"
                        buttonName="Hide"
                        buttonVariation="icon-only"
                    ></button-component>
                    <h4 style="margin: 0">Hide Filter</h4>
                </div>
                <hr class="filter-by-status-separator" />
                <div v-if="showSearchInput" class="search-container">
                    <h4 style="margin: 0; margin-bottom: 10px">
                        Search {{ searchInputLable }}
                    </h4>
                    <mds-search-field
                        class="search-input-component"
                        variation="secondary"
                        @keyup.enter="searchTextEntered()"
                        :placeholder="searchInputPlaceHolder"
                        @mds-search-field-input-cleared="
                            searchFieldCleared($event)
                        "
                        v-model="searchField"
                    >
                    </mds-search-field>

                    <search-results
                        class="search-results-component"
                        searchResultsContainerWidth="500px"
                        :headerData="searchResultsHeaders"
                        :rowData="searchResults()"
                        v-if="searchLength"
                    ></search-results>
                </div>
                <!-- <div class="line-2"></div> -->
                <hr v-if="showSearchInput" class="filter-by-status-separator" />
                <!-- <hr class="line-2" />  -->
                <div>
                    <mds-form action="javascript:void(0)">
                        <div
                            class="filter-by-status"
                            v-for="(filterTypes, index) in filterDataDetails"
                            :key="index"
                        >
                            <!-- <hr class="filter-by-status-separator" /> -->
                            <mds-fieldset
                                :key="MultiKey"
                                v-if="filterTypes.selectionType == 'multiple'"
                                variation="checkbox-group"
                                :legend="filterTypes.filterHeader"
                            >
                                <div style="margin: 5px 0">
                                    <mds-search-field
                                        v-if="filterTypes.showSearchInputField"
                                        class="search-input-component"
                                        :placeholder="filterTypes.placeholder"
                                        @input="
                                            onSearchItemInList(
                                                filterTypes.filterHeader,
                                                $event
                                            )
                                        "
                                        @mds-search-field-input-cleared="
                                            filterTypes.searchText = ''
                                        "
                                        v-model="filterTypes.searchText"
                                    >
                                    </mds-search-field>
                                </div>
                                <div
                                    :style="[
                                        filterDataDetails.length > 1
                                            ? {
                                                  'max-height': '200px',
                                                  'overflow-y': 'auto',
                                              }
                                            : {},
                                        isSetExactHeightForScroll
                                            ? {
                                                  height: '200px',
                                                  'overflow-y': 'auto',
                                              }
                                            : {},
                                    ]"
                                >
                                    <mds-fieldset>
                                        <mds-checkbox
                                            class="checkbox"
                                            v-for="(
                                                filter, indexList
                                            ) in filterTypes.filterList"
                                            :key="indexList"
                                            :value="filter.lable"
                                            :label="filter.lable"
                                            v-model="filter.isChecked"
                                            @change="
                                                filterMultipleSelect(
                                                    $event,
                                                    filterTypes.filterHeader,
                                                    filter
                                                )
                                            "
                                        ></mds-checkbox>
                                    </mds-fieldset>
                                </div>
                            </mds-fieldset>
                            <mds-fieldset
                                v-if="filterTypes.selectionType == 'single'"
                                variation="checkbox-group"
                                :legend="filterTypes.filterHeader"
                            >
                                <div style="margin: 5px 0">
                                    <mds-search-field
                                        v-if="filterTypes.showSearchInputField"
                                        class="search-input-component"
                                        @input="
                                            onSearchItemInList(
                                                filterTypes.filterHeader,
                                                $event
                                            )
                                        "
                                        @mds-search-field-input-cleared="
                                            filterTypes.searchText = ''
                                        "
                                        v-model="filterTypes.searchText"
                                    >
                                    </mds-search-field>
                                </div>
                                <div
                                    :style="[
                                        filterDataDetails.length > 1
                                            ? {
                                                  'max-height': '200px',
                                                  'overflow-y': 'auto',
                                              }
                                            : {},
                                    ]"
                                >
                                    <mds-fieldset>
                                        <mds-radio-button
                                            name="filter"
                                            class="checkbox"
                                            v-for="(
                                                filter, indexRadioList
                                            ) in filterTypes.filterList"
                                            :key="indexRadioList"
                                            :value="filter.id"
                                            v-model="radioFilter"
                                            :label="filter.lable"
                                            :disabled="filter.isdisabled"
                                            @change="
                                                filterObjectCreator(
                                                    $event,
                                                    'Type',
                                                    filter.id
                                                )
                                            "
                                        ></mds-radio-button>
                                    </mds-fieldset>
                                </div>
                            </mds-fieldset>

                            <mds-fieldset
                                v-if="
                                    filterTypes.selectionType == 'dropdown' ||
                                    filterTypes.selectionType ==
                                        'singleSelection'
                                "
                                variation="checkbox-group"
                                :class="
                                    filterTypes.selectionType ==
                                    'singleSelection'
                                        ? 'filter_panel_combo'
                                        : ''
                                "
                            >
                                <div style="margin: 5px 0">
                                    <mds-search-field
                                        v-if="filterTypes.showSearchInputField"
                                        class="search-input-component"
                                        @input="
                                            onSearchItemInList(
                                                filterTypes.filterHeader,
                                                $event
                                            )
                                        "
                                        @mds-search-field-input-cleared="
                                            filterTypes.searchText = ''
                                        "
                                        v-model="filterTypes.searchText"
                                    >
                                    </mds-search-field>
                                </div>
                                <div
                                    :style="[
                                        filterDataDetails.length > 1
                                            ? { 'max-height': '200px' }
                                            : {},
                                    ]"
                                >
                                    <mds-fieldset>
                                        <mds-combo-box
                                            name="filter"
                                            class="checkbox"
                                            placeholder="Select"
                                            :label="filterTypes.filterHeader"
                                            v-model="selectComboValue"
                                            :data-set="filterTypes.filterList"
                                        ></mds-combo-box>
                                        <mds-switch
                                            v-if="
                                                filterTypes.selectionType ==
                                                'dropdown'
                                            "
                                            :disabled="isDisplayChildEntityDisabled"
                                            v-model="displayChildEntity"
                                            @change="filterSwitch($event)"
                                            >Display Child Entity</mds-switch
                                        >
                                    </mds-fieldset>
                                </div>
                            </mds-fieldset>

                            <mds-fieldset
                                v-if="filterTypes.selectionType === 'daterange'"
                                :legend="filterTypes.filterHeader"
                            >
                                <div>
                             
                                    <mds-date-picker
                                        v-model="
                                            filterTypes.filterList[0].value
                                        "
                                        :value="filterTypes.filterList[0].value"
                                        :min-max-dates="
                                            filterTypes.filterList[0]
                                                .minMaxDates
                                        "
                                        :highlightToday="true"
                                        :date-format="{locale:'en-gb'}"
                                        label=""
                                        placeholder="Select a Start Date"
                                        :error="(filterTypes.filterList[0].value > filterTypes.filterList[1].value) 
                                            && (filterTypes.filterList[0].value !== '' && filterTypes.filterList[1].value !== '')"
                                        :error-text="[`'from date' cannot be bigger than 'to date'`]"
                                        @input="updateCurrentState"
                                    ></mds-date-picker>
                                    <span
                                        style="
                                            display: flex;
                                            justify-content: center;
                                            align-content: center;
                                        "
                                    >
                                        to
                                    </span>
                                    <mds-date-picker
                                        v-model="
                                            filterTypes.filterList[1].value
                                        "
                                        :value="filterTypes.filterList[1].value"
                                        :min-max-dates="
                                            filterTypes.filterList[1]
                                                .minMaxDates
                                        "
                                        :highlightToday="true"
                                        :date-format="{locale:'en-gb'}"
                                        label=""
                                        placeholder="Select a End Date"
                                        :disable-dates="disableDates"
                                        :error="(filterTypes.filterList[0].value > filterTypes.filterList[1].value) 
                                            && (filterTypes.filterList[0].value !== '' && filterTypes.filterList[1].value !== '')"
                                        :error-text="[`'to date' cannot be smaller than 'from date'`]"
                                    ></mds-date-picker>

                                    <span>
                                        <mds-button
                                            style="margin-top: 10px"
                                            variation="secondary"
                                            :disabled="
                                                filterTypes.filterList[0]
                                                    .value >
                                                    filterTypes.filterList[1]
                                                        .value ||
                                                filterTypes.filterList[0]
                                                    .value >
                                                    filterTypes.filterList[1]
                                                        .value ||
                                                !filterTypes.filterList[0]
                                                    .value ||
                                                !filterTypes.filterList[1].value
                                            "
                                            @click.prevent="
                                                applyDateRange(
                                                    filterTypes.filterList[0]
                                                        .value,
                                                    filterTypes.filterList[1]
                                                        .value,
                                                    filterTypes.filterHeader
                                                )
                                            "
                                        >
                                            Apply
                                        </mds-button>
                                    </span>
                                </div>
                            </mds-fieldset>

                            <!-- this block is to implement only single search bar at any position in the filter panel -->
                            <mds-fieldset
                                v-if="
                                    filterTypes.selectionType === 'singleSearch'
                                "
                                :legend="filterTypes.filterHeader"
                            >
                                <mds-search-field
                                    class="search-input-component"
                                    variation="secondary"
                                    @keyup.enter="
                                        singleSearchInputTextEntered(
                                            filterTypes.filterHeader,
                                            filterTypes.searchText
                                        )
                                    "
                                    :placeholder="
                                        filterTypes.singleSearchInputPlaceHolder
                                    "
                                    @input="
                                        singleSearchInputCleared(
                                            filterTypes.filterHeader,
                                            filterTypes.searchText
                                        )
                                    "
                                    @mds-search-field-input-cleared="
                                        singleSearchInputTextCleared(
                                            filterTypes.filterHeader
                                        )
                                    "
                                    v-model="filterTypes.searchText"
                                >
                                </mds-search-field>
                            </mds-fieldset>

                            <mds-fieldset
                                v-if="filterTypes.selectionType === 'date'"
                                :legend="filterTypes.filterHeader"
                            >
                                <div>
                                    <mds-date-picker
                                        v-model="
                                            filterTypes.filterList[0].value
                                        "
                                        :value="filterTypes.filterList[0].value"
                                        :min-max-dates="
                                            filterTypes.filterList[0]
                                                .minMaxDates
                                        "
                                        :highlightToday="true"
                                        :date-format="{locale:'en-gb'}"
                                        label=""
                                        placeholder="Select a Date"
                                    ></mds-date-picker>
                                    <span>
                                        <mds-button
                                            style="margin-top: 10px"
                                            variation="secondary"
                                            @click.prevent="
                                                applyDate(
                                                    filterTypes.filterList[0]
                                                        .value,
                                                    filterTypes.filterHeader
                                                )
                                            "
                                        >
                                            Apply
                                        </mds-button>
                                    </span>
                                </div>
                            </mds-fieldset>
                        </div>
                    </mds-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { MdsButton } from "@mds/button";
import ButtonComponent from "../ui_component/ButtonComponent.vue";
import MdsForm from "@mds/form";
import MdsCheckbox from "@mds/checkbox";
import MdsRadioButton from "@mds/radio-button";
import MdsFieldset from "@mds/fieldset";
import MdsSearchField from "@mds/search-field";
import MdsComboBox from "@mds/combo-box";
import MdsSwitch from "@mds/switch";
// import MdsInput from "@mds/input";
import MdsDatePicker from "@mds/date-picker";

export default {
    components: {
        ButtonComponent,
        MdsForm,
        MdsCheckbox,
        MdsFieldset,
        MdsSearchField,
        MdsRadioButton,
        MdsComboBox,
        MdsSwitch,
        // MdsInput,
        MdsButton,
        MdsDatePicker,
    },
    props: [
        "filterData",
        "searchInputLable",
        "searchInputText",
        "selectedEntity",
        "isFilterPanelOpen",
        "showSearchInput",
        "searchInputPlaceHolder",
        "isClearAllDisabled",
        "hideFilterPanelProp",
        "selectCheckboxId",
        "sortOnMultiSelectChange",
        "childEntity",
        "setExactHeightForScroll",
        "componentName",
        "isDisplayChildEntityDisabled"
    ],
    data() {
        return {
            isSetExactHeightForScroll: false,
            displayChildEntity: false,
            stopSortingOnMultiSelectChange: false,
            MultiKey: 0,
            formKey: 0,
            multipleSelected: [],
            hideFilterPanel: this.hideFilterPanelProp,
            checkOldSearchText: "",
            filterDataDetails: [],
            searchField: "",
            searchLength: false,
            filterArray: [],
            filterObject: {},
            radioFilter: this.selectedEntity,
            selectComboValue: [this.selectedEntity],
            fromDate: "",
            toDate: "",
            date: "",
            selectedValues: [],
            filterDataDuplicated: [],
            startDate: "",
            endDate: "",
            disableDates: () => false,
        };
    },
    watch: {
        hideFilterPanelProp: {
            handler: "onHideFilterChange",
        },
        searchInputText(newVal) {
            this.searchField = newVal;
        },
        filterData: {
            immediate: true,
            deep: true,
            handler: "onFilterDataChange",
        },
        selectedEntity(newVal) {
            this.radioFilter = newVal;
            this.selectComboValue = [newVal];
        },
        // searchField(val) {
        //   this.$emit("filterEvent", val);
        // },
        radioFilter(val) {
            if (this.searchInputText) {
                this.searchField = this.searchInputText;
            } else {
                this.searchField = "";
            }
            this.checkOldSearchText = null;
            this.$emit("onFilterChange", val);
            // this.$emit('filterEvent', val);
        },
        selectComboValue(val) {
            if (this.searchInputText) {
                this.searchField = this.searchInputText;
            } else {
                this.searchField = "";
            }
            this.checkOldSearchText = null;
            if (this.selectedEntity != this.selectComboValue[0]) {
                this.searchField = "";
                this.checkOldSearchText = "";
                if (this.$route.path == "/data-management/entities") {
                    this.multipleSelected = [];
                    let selectedCheckboxes = document.querySelectorAll(
                        "input[type=checkbox]:checked"
                    );
                    // clicking all the checked checkboxes to uncheck them
                    selectedCheckboxes.forEach((elem) => elem.click());
                }
                this.$emit("onFilterComboValue", val[0]);
            }
        },
        searchField(val) {
            if (!val.length) {
                this.searchFieldCleared();
            }
        },
        // filterObject() {
        //   this.dynamicFilterObject = {};

        //   // adding only those properties from filterObject to dynamicFilterObject which have some value inside them
        //   for (const prop in this.filterObject) {
        //     if (
        //       this.filterObject[prop] === null ||
        //       this.filterObject[prop] === ""
        //     ) {
        //       continue;
        //     } else {
        //       this.dynamicFilterObject[`${prop}`] = this.filterObject[prop];
        //     }
        //   }
        // },
    },
    mounted() {
        this.displayChildEntity = this.childEntity;
        if (this.setExactHeightForScroll) {
            this.isSetExactHeightForScroll = this.setExactHeightForScroll;
        } else {
            this.isSetExactHeightForScroll = false;
        }
        if (this.sortOnMultiSelectChange != undefined) {
            this.stopSortingOnMultiSelectChange = this.sortOnMultiSelectChange;
        }
        this.selectComboValue.push(this.selectedEntity);
        if (this.searchInputText) {
            this.searchField = this.searchInputText;
        }
        this.hideFilterPanel = !this.isFilterPanelOpen;
    },
    methods: {
        onCheck(v, id, header) {
            if (v) {
                for (let i = 0; i < this.filterData.length; i++) {
                    if (this.filterData[i].filterHeader === header) {
                        for (
                            let j = 0;
                            j < this.filterData[i].filterList.length;
                            j++
                        ) {
                            if (this.filterData[i].filterList[j].id == id) {
                                this.filterData[i].filterList[j].isChecked = v;
                            }
                        }
                    }
                }
            }
        },
        onFilterDataChange() {
            this.filterDataDetails = [];
            for (var i = 0; i < this.filterData.length; i++) {
                var filterObject = {
                    placeholder: this.filterData[i].placeholder,
                    filterHeader: this.filterData[i].filterHeader,
                    filterList: [],
                    searchText: this.filterData[i].searchText,
                    selectionType: this.filterData[i].selectionType,
                    showSearchInputField:
                        this.filterData[i].showSearchInputField,
                    singleSearchInputPlaceHolder:
                        this.filterData[i].singleSearchInputPlaceHolder !== ""
                            ? this.filterData[i].singleSearchInputPlaceHolder
                            : "",
                };
                for (var j = 0; j < this.filterData[i].filterList.length; j++) {
                    let filterListObject;
                    if (this.filterData[i].selectionType == "multiple") {
                        // this is the condition for the id you want to show selected in filter panel
                        if (
                            this.selectCheckboxId !== undefined &&
                            this.filterData[i].filterList[j].id ===
                                this.selectCheckboxId[0]
                        ) {
                            filterListObject = {
                                lable: this.filterData[i].filterList[j].lable,
                                id: this.filterData[i].filterList[j].id,
                                isChecked: true,
                            };
                        } else {
                            if (this.filterData[i].filterList[j].isChecked) {
                                filterListObject = {
                                    lable: this.filterData[i].filterList[j]
                                        .lable,
                                    id: this.filterData[i].filterList[j].id,
                                    isChecked: true,
                                };
                            } else {
                                filterListObject = {
                                    lable: this.filterData[i].filterList[j]
                                        .lable,
                                    id: this.filterData[i].filterList[j].id,
                                    isChecked: false,
                                };
                            }
                        }
                    } else if (
                        this.filterData[i].selectionType == "dropdown" ||
                        this.filterData[i].selectionType == "singleSelection"
                    ) {
                        filterListObject = {
                            text: this.filterData[i].filterList[j].lable,
                            value: this.filterData[i].filterList[j].id,
                        };
                    } else if (
                        this.filterData[i].selectionType == "daterange"
                    ) {
                        filterListObject = {
                            lable: this.filterData[i].filterList[j].lable,
                            id: this.filterData[i].filterList[j].id,
                            isdisabled:
                                this.filterData[i].filterList[j].isdisabled,
                            value: this.filterData[i].filterList[j].value,
                            minMaxDates:
                                this.filterData[i].filterList[j].minMaxDates,
                            // mindate: this.filterData[i].filterList[j].mindate,
                            // maxdate: this.filterData[i].filterList[j].maxdate,
                        };
                    } else if (this.filterData[i].selectionType == "date") {
                        filterListObject = {
                            lable: this.filterData[i].filterList[j].lable,
                            id: this.filterData[i].filterList[j].id,
                            isdisabled:
                                this.filterData[i].filterList[j].isdisabled,
                            value: this.filterData[i].filterList[j].value,
                            minMaxDates:
                                this.filterData[i].filterList[j].minMaxDates,
                            // mindate: this.filterData[i].filterList[j].mindate,
                            // maxdate: this.filterData[i].filterList[j].maxdate,
                        };
                    } else {
                        filterListObject = {
                            lable: this.filterData[i].filterList[j].lable,
                            id: this.filterData[i].filterList[j].id,
                            isdisabled:
                                this.filterData[i].filterList[j].isdisabled,
                        };
                    }
                    filterObject.filterList.push(filterListObject);
                }
                this.filterDataDetails.push(filterObject);
            }
            if (this.sortOnMultiSelectChange == undefined) {
                for (let m = 0; m < this.filterDataDetails.length; m++) {
                    if (this.filterDataDetails[m].selectionType == "multiple") {
                        this.filterDataDetails[m].filterList =
                            this.filterDataDetails[m].filterList.sort(
                                (a, b) => b.isChecked - a.isChecked
                            );
                    }
                }
            }
            this.filterDataDuplicated = JSON.parse(
                JSON.stringify(this.filterDataDetails)
            );
            this.MultiKey += 1;
        },
        onSearchItemInList(header, event) {
            for (var i = 0; i < this.filterDataDuplicated.length; i++) {
                if (this.filterDataDuplicated[i].filterHeader == header) {
                    /* this.filterDataDetails[i].filterList = headerObject[0].filterList.filter(d => d.lable.toLowerCase()
            .includes(event.toLowerCase())) */
                    if (this.filterDataDuplicated[i].filterList.length > 0) {
                        if (event) {
                            this.filterDataDetails[i].filterList =
                                this.filterDataDuplicated[i].filterList.filter(
                                    (item) =>
                                        item.lable
                                            .toLowerCase()
                                            .includes(event.toLowerCase())
                                );
                            // this.filterDataDetails[i].filterList = this.filterDataDetails[
                            //   i
                            // ].filterList.sort((a, b) => {
                            //   if (
                            //     a.lable.toLowerCase().includes(event.toLowerCase()) &&
                            //     b.lable.toLowerCase().includes(event.toLowerCase())
                            //   )
                            //     return a.lable
                            //       .toLowerCase()
                            //       .localeCompare(b.lable.toLowerCase());
                            //   else if (a.lable.toLowerCase().includes(event.toLowerCase()))
                            //     return -1;
                            //   else if (b.lable.toLowerCase().includes(event.toLowerCase()))
                            //     return 1;

                            //   return a.lable.toLowerCase().localeCompare(b.lable);
                            // });
                        } else {
                            this.filterDataDetails[i].filterList =
                                this.filterDataDuplicated[i].filterList.sort(
                                    (a, b) => a.id - b.id
                                );
                            this.filterDataDetails[i].filterList =
                                this.filterDataDuplicated[i].filterList.sort(
                                    (a, b) => b.isChecked - a.isChecked
                                );
                        }
                    } else {
                        this.$emit("onSingleInputSearch", event, header);
                    }
                }
            }
        },
        onClickHideFilterPannel() {
            this.hideFilterPanel = true;
            this.$emit("hideFilterPanel");
        },
        openFilterPanel() {
            this.hideFilterPanel = false;
            this.$emit("showFilterPanel", this.selectedValues);
        },
        searchFieldCleared(e) {
            if (e) e.target.blur();
            this.searchField = "";
            this.checkOldSearchText = "";
            this.$emit("clearInputFilter");
        },
        searchTextEntered() {
            if (this.searchField) {
                if (this.checkOldSearchText != this.searchField) {
                    this.$emit("filterEvent", this.searchField);
                    this.checkOldSearchText = this.searchField;
                }
            } else {
                if (this.checkOldSearchText != this.searchField) {
                    this.$emit("clearInputFilter");
                    this.checkOldSearchText = this.searchField;
                }
            }
        },

        singleSearchInputTextEntered(header, value) {
            this.$emit("onSingleInputSearchText", header, value);
        },

        singleSearchInputTextCleared(header) {
            this.$emit("onSingleInputSearchCleared", header);
        },

        singleSearchInputCleared(header, value) {
            if (!value.length) {
                this.$emit("onSingleInputSearchCleared", header);
            }
        },

        applyDateRange(fromDate, toDate, type) {
            if (fromDate <= toDate) {
                this.$emit(
                    "dateRange",
                    fromDate,
                    toDate,
                    type,
                    this.filterDataDetails
                );
            }
        },

        applyDate(date, type) {
            if (date) {
                this.$emit("date", date, type);
            } else {
                console.log("error");
            }
        },

        //Clear the search input field using $ref
        clearSearchInputFields() {
            this.searchField = "";
            this.checkOldSearchText = null;
        },

    // returns the desired rows by Name column
    searchResults() {
      // return this.getJSON().filter((item) =>
      //   item.Name.toLowerCase().includes(this.searchField.toLowerCase())
      // );
    },
    clearAll() {
      this.searchField = "";
      this.checkOldSearchText = "";
      this.multipleSelected = [];
      if((this.componentName != "activityHistory" && this.componentName != "appMatrix" && this.componentName != "completedTask") || this.componentName==undefined){
        
        setTimeout(()=>this.unSelectAll(),1000);
        
      }
      this.$emit("clearAllFilters");
    },
    unSelectAll() {
      this.selectedValues = [];
        let filterPanel = document.querySelector("#filter-panel");
        var items =  filterPanel.querySelectorAll(
          "input[type=checkbox]:checked"
        );
        for (var i = 0; i < items.length; i++) {
            // if (items[i].type == 'checkbox' && items[i].getAttribute('role') !== 'switch') {
              items[i].checked = false;
            // }
        }
        for (let i = 0; i < this.filterData.length; i++) {
          for (let j = 0; j < this.filterData[i].filterList.length; j++) {
            this.filterData[i].filterList[j].isChecked = false;
          }
        }
    },

        reset() {
            let selectedCheckboxes = document.querySelectorAll(
                "input[type=checkbox]:checked"
            );
            // clicking all the checked checkboxes to uncheck them
            selectedCheckboxes.forEach((elem) => elem.click());
            this.filterArray = [];
        },
        
        //used to uncheck the items on reset
        resetSelectedValue(){
            this.selectedValues = [];
            this.searchField = "";
            this.checkOldSearchText = "";
            this.multipleSelected = [];
        },

        filterMultipleSelect(event, headerTypeDetails, value) {
            //this.searchField=""
            const item = { headerTypeDetails, value };
            if (event) {
                this.selectedValues.push(item);
            } else {
                const index = this.selectedValues.findIndex(
                    (element) =>
                        element.headerTypeDetails === item.headerTypeDetails
                );
                if (index > -1) {
                    this.selectedValues.splice(index, 1);
                }
            }
            this.checkOldSearchText = null;
            this.$emit(
                "multiSelectFilterChange",
                event,
                value,
                headerTypeDetails
            );
        },

        filterObjectCreator(event, propertyType, value) {
            var obj = {
                event: event,
                propertyType: propertyType,
                value: value,
            };
            // this.selectedItems = propertyType;
            this.searchField = "";
            this.checkOldSearchText = null;
            this.$emit("onFilterChange", obj);
        },

        filterSwitch(event) {
            if (!this.isDisplayChildEntityDisabled) {
                this.$emit("onFilterSwitch", event);
            }
        },

        checkValidDate(date) {
            return (
                !!date &&
                typeof date.getTime === "function" &&
                date.toString() !== "Invalid Date"
            );
        },
        updateCurrentState(value) {
            this.startDate = value;
            this.endDate = "";
            this.disableDates = this.checkValidDate(value)
                ? (date) => date.getTime() <= value.getTime()
                : () => false;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.dataPointSubLabel {
    @include mds-eyebrow-heading();
}

.filter-section {
    padding: 0px;
    margin-left: 0;
    height: 1000px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: none;
    background-color: #ffffff;
    overflow-y: auto;
    max-height: 92vh;
}

.filter-header-section {
    margin: 0px;
    height: auto;
    width: 100%;
    background-color: $mds-color-neutral-95;
    display: grid;
    grid-template-columns: 50% 50%;
}

.filter-header {
    margin: 0px;
    padding-left: 16px;
    color: #1e1e1e;
    font-size: 16px;
    display: flex;
    align-content: center;
    // justify-content: center;
}

.reset-button {
    text-align: right;
    float: right;
    padding-right: 17.53px;
    color: #5e5e5e;
}

.reset-content {
    float: right;
    cursor: pointer;
}

.search-container {
    width: 100%;
    margin: 16px 0;
}

.search-input-component {
    //   margin-left: 17.53px;
    //   margin-right: 17.47px;
    background-color: $mds-color-neutral-95;
    border-radius: 15px;
    white-space: nowrap;
}

.search-results-component {
    margin-top: 10px;
    position: relative;
    left: -95%;
}

.filter-by-type-separator {
    border: 0.7px solid #cccccc;
}

.filter-by-type {
    margin-bottom: 24px;
}

.filter-by-status-separator {
    border: 0.7px solid #cccccc;
    margin: 16px 0;
}

.filter-by-status {
    margin-bottom: 24px;
}

.filter-by-Health-separator {
    border: 0.7px solid #cccccc;
}

.filter-header-hide-filterPanel {
    color: #1e1e1e;
    font-size: 16px;
    display: flex;
    align-content: center;
    padding: 0;
    margin: 16px 0;
}

.filter-by-health {
    margin-bottom: 346px;
}
.line-2 {
    box-sizing: border-box;
    height: 1px;
    border: 1px solid #e5e5e5;
    margin: 15px 0;
}

.filter_panel_combo {
    height: 300px;
}

#filter-panel::v-deep
    .mds-date-picker___VueMDS3Demo
    .mds-date-picker__container___VueMDS3Demo {
    width: 250px;
}

#filter-panel::v-deep .filter-header .mds-icon___VueMDS3Demo {
    height: 20px;
    width: 20px;
}
#filter-panel::v-deep
    .mds-combo-box___VueMDS3Demo
    .mds-combo-box__result-list___VueMDS3Demo {
    max-height: 250px;
}
</style>
