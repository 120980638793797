<template>
  <div id="dialogPopup" class="dialog-popup">
    <mds-dialog v-model="toggleDialog" action-required :title="title">
      <span v-if="selectedAction !== 'Duplicate'">
        {{ subTitle }}
      </span>
      <template v-slot:mds-dialog-supplemental-content>
        <mds-form action="javascript:void(0)">
          <mds-fieldset>
            <mds-checkbox
              v-if="selectedAction === 'Delete'"
              value="deleteCheckboxlabel"
              @change="deleteCheckboxlabel($event)"
            >
              {{ checkboxLabel }}
            </mds-checkbox>
            <mds-input
              v-if="checkAction"
              label=""
              v-model.trim="textFieldValue"
            ></mds-input>
          </mds-fieldset>
        </mds-form>
      </template>
      <template v-slot:mds-dialog-actions-right>
        <mds-button-container right-aligned>
          <mds-button type="button" variation="secondary" @click="cancelDialog">
            <span v-if="selectedAction === 'Publish'">
              No
            </span>
            <span v-else>
              Cancel
            </span>
          </mds-button>
          <mds-button
            id="dialog-popup-delete"
            v-if="selectedAction === 'Delete'"
            type="button"
            :disabled="deletePopupButtonDisable"
            @click="deletePopup"
          >
            Delete {{ deleteLabel }}
          </mds-button>
          <mds-button
            v-if="checkAction"
            type="button"
            variation="primary"
            :disabled="duplicateTemplateButtonDisabled"
            @click="saveDialog"
          >
            Save
          </mds-button>
          <mds-button
            v-if="selectedAction === 'Publish'"
            type="button"
            variation="primary"
            @click="publishDialog"
          >
            Yes, Publish
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>
  </div>
</template>

<script>
import MdsDialog from "@mds/dialog";
import MdsForm from "@mds/form";
import MdsCheckbox from "@mds/checkbox";
import MdsFieldset from "@mds/fieldset";
import MdsInput from "@mds/input";
import { MdsButton, MdsButtonContainer } from "@mds/button";

export default {
  name: "delete-popup",
  components: {
    MdsDialog,
    MdsForm,
    MdsCheckbox,
    MdsFieldset,
    MdsButton,
    MdsButtonContainer,
    MdsInput,
  },
  props: {
    toggleDialog: {
      type: Boolean,
      default: false,
    },
    selectedAction: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    checkboxLabel: {
      type: String,
      default: "",
    },
    deleteLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      deletePopupButtonDisable: true,
      textFieldValue: "",
    };
  },
  methods: {
    deleteCheckboxlabel(e) {
      if (e == true) {
        this.deletePopupButtonDisable = false;
      } else {
        this.deletePopupButtonDisable = true;
      }
    },
    cancelDialog() {
      this.$emit("cancel");
      this.textFieldValue = "";
      this.deletePopupButtonDisable = true;
    },
    saveDialog() {
      const data = {
        textFieldValue: this.textFieldValue,
        selectedAction: this.selectedAction,
      };
      this.$emit("emitSaveModalPopup", data);
      this.textFieldValue = "";
    },
    publishDialog() {
      this.$emit("emitPublishPopup");
    },
    deletePopup(){
      
      this.deletePopupButtonDisable = true;
      this.$emit('emitDeletePopup')
    }
  },
  computed: {
    duplicateTemplateButtonDisabled() {
      return !this.textFieldValue;
    },
    checkAction() {
      return (
        this.selectedAction === "Duplicate" ||
        this.selectedAction === "Edit" ||
        this.selectedAction === "Group"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
 
#dialog-popup-delete {
    background-color: $mds-feedback-color-error;
    color: $mds-background-color-white;
}
</style>
