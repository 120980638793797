<template>
    <div id="Header-Button-Panel">
        <div class="Header-Panel-Outer-Block">
            <div style="display:flex;padding-bottom:5px;justify-content: space-between;">
                <div style="display:flex;">
                    <p v-if="counterDetails && counterDetails.displayCounterDetails"
                        class="flex-buttons counter-text" >
                        {{counterDetails.count}} {{counterDetails.countText}} 
                        </p>
                    <div class="vertical-line" v-if="counterDetails && counterDetails.showVerticalLine"></div>
                    <div style="display:flex" v-for="(button,index) in buttonDetails" :key="index">
                        <!-- <button-component
                            class="flex-buttons"
                            v-if="!button.isText"
                            :id="button.id"
                            :class="button.iconColor"
                            :onClick="addDatapoints"
                            :buttonName="button.buttonName"
                            buttonVariation="flat"
                            :iconName="button.iconName"
                            :disabled="button.isDisabled"
                        >
                        </button-component> -->
                        <mds-button 
                            class="flex-buttons"
                            v-if="!button.isText" 
                            :variation="button.buttonType!=undefined ? button.buttonType:'flat'" 
                            :id="button.id" 
                            :icon="button.iconName"
                            :disabled="button.isDisabled 
                                || (button.permission && !permissions.includes(button.permission) && !permissions.includes('*'))"
                            :class="button.iconColor"
                            @click="onButtonClick(button.buttonName)"
                            type="button"
                            >
                            {{button.buttonName}}
                        </mds-button>
                        <p class="flex-buttons" :class="button.iconColor"  v-if="button.isText" style="    display: flex;
    justify-content: center;
    align-items: center;"><mds-icon :name="button.iconName"></mds-icon> {{button.buttonName}}</p>
                        <div v-if="button.showVerticalLine" class="vertical-line"></div>
                    </div>
                </div>
                <div style="display:flex">
                    <div style="display:flex" v-for="(button,index) in buttonRightDetails" :key="index">
                        <!-- <button-component
                            class="flex-buttons"
                            :id="button.id"
                            :class="button.iconColor"
                            :onClick="onButtonClick"
                            :buttonName="button.buttonName"
                            buttonVariation="flat"
                            :iconName="button.iconName"
                            :disabled="button.isDisabled"
                            :style="index == buttonRightDetails.length-1 ? 'margin-right:0':''"
                        >
                        </button-component> -->
                         <mds-button 
                            class="flex-buttons"
                            variation="flat" 
                            :id="button.id" 
                            :icon="button.iconName"
                            :disabled="button.isDisabled 
                                || (button.permission && !permissions.includes(button.permission) && !permissions.includes('*'))"
                            :class="button.iconColor"
                            @click="onRightSideButtonClick(button.buttonName)"
                            :style="index == buttonRightDetails.length-1 ? 'margin-right:0':''"
                            type="button"
                            >
                            {{button.buttonName}}
                        </mds-button>
                        <div v-if="button.showVerticalLine" class="vertical-line"></div>
                    </div>
                </div>
                <!-- v-if="sourceFile === 'schedule'" -->
                <div v-if="sourceFile === 'schedule'" class="schedule-date">
                    <span>*</span> Populated based on Date Filter
                </div>

                <div v-if="sourceFile === 'documentManagement'">
                    <span>*</span> Only excel file is supported
                </div>
            </div> 
        </div>
    </div>
</template>
<script>
// import ButtonComponent from '../ui_component/ButtonComponent.vue';
import { MdsButton} from '@mds/button'
import MdsIcon from '@mds/icon'
export default {
    components:{
        // ButtonComponent,
        MdsIcon,
        MdsButton
    },
    computed: {
        permissions () {
            return this.$store.state.auth.permissions;
        }
    },
    props:["buttonDetails","counterDetails","buttonRightDetails","sourceFile"],
    methods:{
        onButtonClick(event){
            this.$emit("panelButtonClicked",event)
        },
        onRightSideButtonClick(event){
            this.$emit("rightPanelButtonClicked",event)
        }
    }
}
</script>
<style lang="scss" scoped>
  @import "../../assets/css/styles.scss";
.Header-Panel-Outer-Block{
    width:100%;
    border-bottom: 1px solid #CCCCCC;
}
.vertical-line {
  box-sizing: border-box;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 16px;
  width: 1px;
  border-left: 1px dashed #cccccc;
}
.flex-buttons {
    margin:0;
  margin-right: 16px;
  font-size: 16px;
}
.counter-text{
font-size: 16px;
display: flex;
justify-content: center;
align-items: center;
margin: 0;
margin-right: 16px;
}
#Header-Button-Panel .mds-icon___VueMDS3Demo {
    height: 15px;
    width: 15px;
    margin-right: 6px;
}
#Header-Button-Panel::v-deep .redColor .mds-icon___VueMDS3Demo {
  @include mds-icon-color(#FF0000);
}
#Header-Button-Panel::v-deep .defaultColor .mds-icon___VueMDS3Demo {
 @include mds-icon-color(#5E5E5E);
}
#Header-Button-Panel::v-deep .greenColor .mds-icon___VueMDS3Demo {
  @include mds-icon-color(#00AF41);
}
#Header-Button-Panel::v-deep .redColor.mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo .mds-button__icon___VueMDS3Demo, a.mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo .mds-button__icon___VueMDS3Demo{
@include mds-icon-color(#FF0000);
    // fill: red;
    // stroke: red;
}
#Header-Button-Panel::v-deep .defaultColor.mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo .mds-button__icon___VueMDS3Demo, a.mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo .mds-button__icon___VueMDS3Demo{
@include mds-icon-color(#5E5E5E);
    // fill: red;
    // stroke: red;
}
#Header-Button-Panel::v-deep .greenColor.mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo .mds-button__icon___VueMDS3Demo, a.mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo .mds-button__icon___VueMDS3Demo{
@include mds-icon-color(#00AF41);
    // fill: red;
    // stroke: red;
}
#Header-Button-Panel::v-deep .blueColor.mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo .mds-button__icon___VueMDS3Demo, a.mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo .mds-button__icon___VueMDS3Demo{
@include mds-icon-color(blue);
    // fill: red;
    // stroke: red;
}
</style>