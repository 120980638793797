<template>
    <div>
        <Alerts
            :buttonPanelDetails="buttonPanelDetails"
            :counterDetails="counterDetails"
            :filterObject="filterObject"
            :alertHeader="alertHeader"
            :alertRow="alertRow"
            :menuwidth="menuwidth"
            :totalItems="totalData"
            :pageSize="pageSize"
            :page="page"
            :sortType="sortType"
            :sortOrder="sortOrder"
            :sortColIndex="sortColIndex"
            :orderByColumn="orderByColumn"
            :rowEditDisabledButton="rowEditDisabledButton"
            :rowDeleteDisabledButton="rowDeleteDisabledButton"
            :alertListLoader="alertListLoader"
            :paginationComponentKey="paginationComponentKey"
            @onClickPanelButton="onClickPanelButton"
            @multiSelectFilterChange="multiSelectFilterChange"
            @rowEditActionButton="rowEditActionButton"
            @rowDeleteActionButton="rowDeleteActionButton"
            @paginateTable="paginateTable"
            @clearTextFilter="clearTextFilter"
            @clearAllFilters="clearAllFilters"
            @handleSortEvent="handleSortEvent"
            @searchFilter="searchFilter"
            @openFilterPanel="openFilterPanel"
        ></Alerts>
        <dialog-popup
            :toggleDialog="toggleDialog"
            :title="dialogTitle"
            :sub-title="subTitle"
            :selectedAction="selectedAction"
            checkboxLabel="Yes, delete this alert"
            deleteLabel="alert"
            @cancel="closeDialogPopup"
            @emitDeletePopup="deletePopupModal"
        >
        </dialog-popup>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import store from "@/store";
import Alerts from "../components/Alerts.vue";
import DialogPopup from "@/components/common_components/DialogPopup.vue";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";
import { ALERTS_ACTIONS } from "@/store/modules/alerts/contants";
import get from "lodash/get";
import { mapState } from "vuex";

export default {
    beforeCreate() {
        this.$store = store;
    },
    name: "Alerts-container",
    components: {
        Alerts,
        DialogPopup,
        NotificationComponent,
    },
    data() {
        return {
            counterDetails: {
                displayCounterDetails: false,
                count: 0,
                countText: "Selected",
                showVerticalLine: false,
            },
            alertRow: [],
            totalData: 0,
            page: 1,
            pageSize: 10,
            //sorting
            sortType: false,
            sortOrder: -1,
            sortColIndex: 2,
            orderByColumn: "updatedat",
            selectMultiRowData: [],
            rowEditDisabledButton: false,
            rowDeleteDisabledButton: false,
            //dialog popup
            toggleDialog: false,
            selectedAction: "",
            subTitle: "",
            dialogTitle: "",
            //Notification
            showNotification: false,
            notificationMessage: "",
            notificationType: "",

            alertHeader: [
                {
                    fieldName: "alertname",
                    text: "Alert Name",
                    width: "40%",
                    sortable: true,
                },
                {
                    fieldName: "type",
                    text: "Type",
                    width: "20%",
                    sortable: true,
                },
                {
                    fieldName: "updatedat",
                    text: "Last Updated",
                    width: "30%",
                    sortable: true,
                    sorted: -1,
                },
                {
                    fieldName: "actions",
                    text: "Actions",
                    width: "15%",
                    align: "right",
                },
            ],
            buttonPanelDetails: [
                {
                    id: "AddButtonId",
                    buttonName: "Create Alert",
                    iconName: "plus",
                    iconColor: "defaultColor",
                    isDisabled: false,
                    showVerticalLine: false,
                    isText: false,
                },
            ],

            filterObject: [
                {
                    filterHeader: "Type",
                    selectionType: "multiple",
                    filterList: [],
                }],
            searchFilterText: "",
            filterSearchType: [],
            alertTypeFilter: "",
            paginationComponentKey:0,
        };
    },
    props: {
        menuwidth: {
            type: String,
        },
    },
    async mounted() {
        this.commonFunction();
    },
    computed: {
        // ...mapGetters(["cachedDispatch"]),
        ...mapState({
            alertList(state) {
                return get(state, "alerts.alerts.alerts", []);
            },
            alertListLoader(state) {
                return get(state, "alerts.alerts.__loading__", false);
            },
            alertDelete(state) {
                return get(
                    state,
                    "alerts.deleteAlerts.deleteAlerts",
                    []
                );
            },
            alertDeleteError(state) {
                return get(state, "alerts.deleteAlerts.__error__", null);
            },
        }),
    },
    methods: {
        // common function
        async commonFunction() {
            var data = {
                page: this.page,
                pageSize: this.pageSize,
                searchAlert: this.searchFilterText,
                searchType: this.alertTypeFilter,
                orderByColumn: this.orderByColumn,
                isAscending: this.sortType,
            };
            await this.$store.dispatch(ALERTS_ACTIONS.FETCH_ALERT_LIST, {
                data,
            });
            let response = this.alertList;
            this.alertRow = response.list;
            if(this.alertRow.length === 0){
                this.alertRow = undefined;
            }
            this.totalData = response.total;
        },

        //top header action button
        onClickPanelButton(val) {
            if (val === "Create Alert") {
                this.createAlert();
            }
        },

        createAlert() {
            this.$router.push({
                name: "Settings Create Alert",
            });
        },

        // delete alert
        closeDialogPopup() {
            this.toggleDialog = false;
        },
        async deletePopupModal() {
            this.toggleDialog = false;
            let array = [];
            this.selectMultiRowData.forEach((item) => {
                array.push(item.notificationruleid);
            });
            var notificationRuleId = array.join(",");
            await this.$store.dispatch(
                ALERTS_ACTIONS.FETCH_DELETE_ALERT,
                {
                    notificationRuleId,
                }
            );
            let response = this.alertDelete;
            const obj = {
                response,
                ntfnMsg: "The alert has been deleted.",
                ntfnType: "informational",
                ntfnKey: "informational-example",
                ntfnfailureMsg: this.alertDeleteError,
            };
            this.notificationResponse(obj);
        },

        // row action button
        rowEditActionButton(value) {
            this.$router.push(`/settings/alerts/create?notificationRuleId=${value.notificationruleid}`);
        },

        rowDeleteActionButton(value) {
            this.subTitle = `This action cannot be undone. The alert
        will be deleted for all users with access.`;
            this.dialogTitle = "Delete this alert?";
            this.selectedAction = "Delete";
            this.toggleDialog = true;
            this.selectMultiRowData.length = 0;
            if (this.selectMultiRowData.length == 0) {
                this.selectMultiRowData.push(value);
            }
        },

        // filter-panel
        multiSelectFilterChange(event, value, headerTypeDetails) {
            this.page = 1;
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader == headerTypeDetails) {
                    for (let j = 0; j < this.filterObject[i].filterList.length; j++) {
                        if (this.filterObject[i].filterList[j].lable == value.lable) {
                            this.filterObject[i].filterList[j].isChecked = event;
                        }
                    }
                }
            }
            this.selectMultiRowData.length = 0;
            this.counterDetails.count = 0;
            if (event == true) {
                if (headerTypeDetails == "Type") {
                    this.filterSearchType.push(value.lable);
                }
                this.alertTypeFilter = this.filterSearchType.join(",");
            } else if (event == false) {
                if (headerTypeDetails == "Type") {
                    for (let i = 0; i < this.filterSearchType.length; i++) {
                        if (this.filterSearchType[i] === value.lable) {
                            this.filterSearchType.splice(i, 1);
                        }
                    }
                }
                this.alertTypeFilter = this.filterSearchType.join(",");
            }
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey+=1;
        },
        searchFilter(searchText) {
            this.searchFilterText = searchText;
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey+=1;
        },

        clearTextFilter() {
            this.searchFilterText = "";
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey+=1;
        },

        clearAllFilters() {
            this.reset();
            this.clearTextFilter();
        },

        //sorting

        executeSortingLogicForMdsTable(colIndex) {
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                this.$set(this.alertHeader[this.sortColIndex], "sorted", 0);
                this.sortOrder = -1;
            }

            this.sortColIndex = colIndex;
            this.$set(this.alertHeader[colIndex], "sorted", this.sortOrder);
        },

        handleSortEvent(colIndex, colName) {
            this.executeSortingLogicForMdsTable(colIndex);
            this.orderByColumn = colName;
            this.sortOrder == 1
                ? (this.sortType = true)
                : (this.sortType = false);
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey+=1;
        },

        // pagination
        paginateTable(arg) {
            this.page = arg.page;
            this.pageSize = arg.pageSize;
            this.commonFunction();
        },

        reset() {
            this.searchFilterText = "";
            this.filterSearchType = [];
            this.alertTypeFilter = "";
            this.selectMultiRowData.length = 0;
        },

        // notification event
        notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 200) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
                this.commonFunction();
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
            }
        },

        openFilterPanel(selectedFilter){
            this.getAlertTypeList(selectedFilter);
        },
         getAlertTypeList(selectedFilter) {
            this.filterObject[0].filterHeader = "Type";
            this.filterObject[0].selectionType = "multiple";
            this.filterObject[0].filterList = [
                
                        {
                            id: 1,
                            lable: "Item Change",
                            isChecked: false,
                        },
                        {
                            id: 2,
                            lable: "New Entity",
                            isChecked: false,
                        }
                    ],

            this.filterObject[0].filterList.map(x => {
                    selectedFilter.forEach(y => {
                        if (x.lable === y.value.lable) {
                            x.isChecked = true;
                        }
                    })
                });
         },
           
    },
};
</script>
