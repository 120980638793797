<template>
  <mds-button
    @click.prevent="onClick"
    :variation="buttonVariation"
    :size="buttonSize"
    :icon="iconName"
    id="mdsButtonComponent"
    :disabled="isDisabled"
    style="white-space: nowrap;"
    type="button"
  >
  <!-- :disabled="isDisabled || (permission && !permissions.includes(permission)) " -->
    {{ buttonName }}
  </mds-button>
</template>
<script>
import { MdsButton } from "@mds/button";
export default {
  components: {
    MdsButton,
  },
  computed: {
    // || (permission && !permissions.includes(permission))
    permissions () {
      return this.$store.state.auth.permissions;
    }
  },
  props: {
    buttonVariation: {
      type: String,
      required: true,
    },
    permission: {
      type: String
    },
    buttonSize: {
      type: String,
    },
    onClick: {
      type: Function,
      required: true,
    },
    iconName: {
      type: String,
    },
    buttonName: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
